import React from 'react';
import classes from "./App.module.css";

function App() {
  return (
    <div className={classes.app}>
      <img alt='Main' className={classes.main} src="https://firebasestorage.googleapis.com/v0/b/voxabox-presentation.appspot.com/o/voxabox_presentation_v2-min.jpg?alt=media&token=28ee7e9b-ef75-4ede-9b87-4a015910a211"/>
    </div>
  );
}

export default App;
